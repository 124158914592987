import {parseQueryParams, SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import * as React from 'react'

export const Conference = props => {
  const {conferencingToken} = props
  const apiKey = parseQueryParams(location.href).apiKey
  const token = parseQueryParams(location.href).token
  return (
    <div style={{textAlign: 'center'}}>
      <iframe
        title={'Video Conferencing'}
        width={'100%'}
        height={900}
        scrolling="no"
        frameBorder="0"
        allow="autoplay *; camera *; microphone *; geolocation *; vr *"
        allowTransparency={true}
        allowFullScreen
        src={`${SERVER_BASE_URL}/html/vonage/${conferencingToken}?apiKey=${apiKey}&token=${encodeURIComponent(token)}`}
      />
    </div>
  )
}
