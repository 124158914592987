import {getEventDescription, getEventMainImage, getEventTitle} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {isMembersVisibleWithGuests} from '../../selectors/members'
import {getContentAlignment, getHeaderAlignment, getText, isSocialShareVisible} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {
  isAboutSectionVisible,
  isDescriptionVisible,
  isMapVisible,
  isSecondRegistrationButtonVisible,
} from '../../selectors/event'
import {EventDetailsMobile as Presentation} from './event-details-mobile'
import {EventDetailsOwnProps, EventDetailsRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {handleRSVP, detailsPageLoaded},
}: DetailsPageAppProps): EventDetailsRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  return {
    eventTitle: getEventTitle(event),
    eventDescription: getEventDescription(event),
    mainImage: getEventMainImage(event),
    headerAlignment: getHeaderAlignment(componentSettings),
    contentAlignment: getContentAlignment(componentSettings),
    descriptionVisible: isDescriptionVisible(state),
    timeAndLocationTitleText: getText('timeAndLocationTitleText', componentSettings),
    membersVisible: isMembersVisibleWithGuests(state),
    mapVisible: isMapVisible(state),
    aboutSectionVisible: isAboutSectionVisible(state),
    isSecondRegistrationButtonVisible: isSecondRegistrationButtonVisible(state),
    socialShareVisible: isSocialShareVisible(componentSettings),
    handleRSVP,
    detailsPageLoaded,
  }
}

export const EventDetailsMobile = connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntime)(
  withNamespaces()(Presentation),
)
export * from './interfaces'
